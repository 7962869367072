import animateScrollTo from "animated-scroll-to"

/**
 * Same as jquery $ (wrapper for getElementById), probably simplified - I've never looked at their code.
 * @param {string} id Element ID.
 * @returns {HTMLElement} Element.
 */
var $ = id => document.getElementById(id)

/**
 * Wrapper for document.querySelector.
 * @param {string} s CSS selector string.
 * @returns {HTMLElement} Element.
 */
var q = s => document.querySelector(s)

/**
 * Wrapper for querySelectorAll.
 * @param {string} s CSS selector string.
 * @returns {NodeList} Node list.
 */
var _ = s => document.querySelectorAll(s)

async function doInit() {
	var scrollaway = function (ev) {
		var to = ev.target.getAttribute("data-v-to")
		animateScrollTo($(to), {
			verticalOffset: -30,
			maxDuration: 1200,
			minDuration: 0,
		})
	}

	_(".nav-link").forEach(el => {
		el.addEventListener("click", scrollaway, false)
	})

	if (["localhost", "127.0.0.1", "", "::1"].includes(window.location.hostname)) {
		document.documentElement.classList.add("localhost")
	}
}

function initLubrinor() {
	doInit()
}

if (document.readyState === "loading") {
	document.addEventListener("DOMContentLoaded", initLubrinor)
} else {
	initLubrinor()
}
